<template>
  <v-card
      :flat="flat"
      class="responsive-page-title">
    <v-card-text class="d-flex align-center justify-space-between">
      <div class="d-flex align-center justify-start">
        <v-icon
            color="main"
            size="25"
            class="ml-2 page-title__icon"
            v-if="icon">
          {{ icon }}
        </v-icon>
        <div class="page-title__title">
          {{ title }}
        </div>
      </div>
      <slot></slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "PageTitleResponsive",
  props: {
    flat: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: null,
    },
  }
}
</script>

<style scoped>
.responsive-page-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc !important;
  border-radius: 0!important;
  padding: 0;
}

.responsive-page-title .page-title__title {
  font-size: 16px;
  color: var(--v-main-base) !important;
}
.responsive-page-title .v-card__text {
padding: 10px 0 ;
}
</style>