<template>
<component v-bind="$props"  :is="getActiveComponent"><slot></slot></component>
</template>

<script>
import PageTitleResponsive from "./Responsive"
import PageTitleDesktop from "./Desktop"
export default {
  name: 'PageTitle',
  components:{
    PageTitleResponsive,
    PageTitleDesktop
  },
  props: ['flat', 'title', 'icon'],
  computed: {
    getActiveComponent() {
      return this.$device.mobile ? 'PageTitleResponsive' : 'PageTitleDesktop'
    }
  }
}
</script>
