<template>
  <div>
    <v-card flat>
      <v-card-title>
        <PageTitle
            class="w-100"
            icon="mdi-chart-bar"
            title="آمار مشترکین"
        />
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
              :cols="12"
              :lg="3"
          >
            <v-text-field
                v-model="filters.startday"
                hide-details
                outlined
                label="از تاریخ"
                class="start-date-picker"
            />
            <date-picker
                auto-submit
                v-model="filters.startday"
                format="jYYYY/jMM/jDD"
                custom-input=".start-date-picker"
            />

          </v-col>

          <v-col
              :cols="12"
              :lg="3"
          >
            <v-text-field
                v-model="filters.endday"
                hide-details
                outlined
                label="تا تاریخ"
                class="end-date-picker"
            />
            <date-picker
                auto-submit
                v-model="filters.endday"
                format="jYYYY/jMM/jDD"
                custom-input=".end-date-picker"
            />

          </v-col>

          <v-col
              :cols="12"
              :lg="3"
          >
            <v-btn
                elevation="0"
                :disabled="!( filters.startday && filters.endday)"
                :loading="filters.loading"
                @click="fetchData"
                block
                color="primary"
                x-large
            >
              نمایش آمار
            </v-btn>
          </v-col>

          <v-col :cols="12"
                 :lg="4">
            <h3 class="mb-2">
              تعداد کل مشترکین :
              {{ totalcount }}
            </h3>
          </v-col>

          <v-col :cols="12"
                 :lg="4">
            <h3 class="mb-2">
              تعداد فعال ها :
              {{ allactive }}
            </h3>
          </v-col>

          <v-col :cols="12"
                 :lg="4">
            <h3 class="mb-2">
              تعداد غیر فعال ها :
              {{ alldeactive }}
            </h3>
          </v-col>

          <v-col class="mt-4" :cols="12" :lg="12">
            <div id="chart"></div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import {fetchChartData} from "@Newfiling/module-subscription/src/api";
import Highcharts from "highcharts";
import moment from "jalali-moment";

export default {
  name: "Statistics",

  components: {
    PageTitle,
    datePicker: VuePersianDatetimePicker,
  },

  data() {
    return {
      totalcount: 0,
      allactive: 0,
      alldeactive: 0,
      admins: [],
      filters: {
        loading: false,
        startday: moment().locale("fa").format("jYYYY/jMM/jDD"),
        endday: moment().locale("fa").format("jYYYY/jMM/jDD"),
      },
    };
  },

  beforeMount() {
    this.$store.commit("Breadcrumb/setBreadcrumb", [
      {
        text: "پنل مدیریت نیوفایل",
        disabled: false,
        href: "/panel/dashboard",
      },
      {
        text: "لیست مشترکین",
        disabled: false,
        href: "/panel/subscriptions/list",
      },
      {
        text: "آمار مشترکین",
        disabled: true,
        href: "#",
      },
    ]);
  },

  methods: {
    async fetchData() {
      this.filters.loading = true;
      try {
        const data = (await fetchChartData(this.filters))?.data || {};
        this.totalcount = data.totalcount;
        this.allactive = data.allactive;
        this.alldeactive = data.alldeactive;
        const res = data.message || [];
        const xAxis = res.map((item) => item.date);
        const yAxis = res.map((item) => item.numberofsubscribers);
        Highcharts.chart("chart", {
          chart: {
            type: "column",
          },
          title: {
            text: "آمار مشترکین",
            style: {
              color: "#000",
              fontFamily: '"iranyekan"',
              fontSize: "16px",
              fontWeight: "bold",
            },
            useHTML: true,
          },
          xAxis: {
            labels: {
              style: {
                color: "#000",
                fontFamily: '"iranyekan"',
                fontSize: "14px",
                fontWeight: "bold",
              },
            },
            categories: xAxis,
            crosshair: false,
          },
          yAxis: {
            min: 0,
            title: {
              style: {
                color: "#000",
                fontFamily: '"iranyekan"',
                fontSize: "14px",
                fontWeight: "bold",
              },
              text: "",
              useHTML: true,
            },
          },
          series: [
            {
              type: "column",
              colorByPoint: false,
              showInLegend: true,
              name: "",
              dataLabels: {
                enabled: true,
              },
              data: yAxis,
            },
          ],
          plotOptions: {
            column: {
              pointPadding: 0.2,
              borderWidth: 0,
            },
          },
          labels: {
            skew3d: true,
            style: {
              fontSize: "16px",
              fontFamily: '"iranyekan"',
            },
          },
        });
      } catch (e) {
        console.log(e);
      }
      this.filters.loading = false;
    },
  },
};
</script>

<style scoped>

</style>